<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Overview" />
    <a-row
      type="flex"
      justify="space-around"
      align="middle"
      class="content"
      style="height: calc(100vh - 300px)"
    >
      <a-col> <h1 style="font-size: 100px">Workstats</h1> </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import Chart from "@/components/Chart.vue";
export default {
  components: {
    Chart,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
</style>
